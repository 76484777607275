.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  /* flex-wrap: wrap; */
  flex-direction: row;
  width: 85%;
  margin: 40px auto;
  /* margin: 90px auto 40px; */
}
.header-section h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  color: #314259;
  margin-block: 20px;
}
.header-section img {
  width: 444px;
  height: 316px;
  display: block;
}
.header-section button {
  background-color: #ed1a3b;
  color: #fff;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 15px;
  border: 1px solid #ed1a3b;
  margin-block: 30px;
}

.feature {
  background: #fafafa;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 25px;
  flex-direction: row;
  width: 85%;
  margin: 20px auto;
  padding: 20px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.feature .tab {
  width: 30vh;
  max-width: 30vh;
  min-width: 20vh;
}

.feature img {
  size: 56px;
  margin-bottom: 12px;
}
.feature h4 {
  font-size: 25px;
  color: #314259;
  margin-bottom: 10px;
}
.feature p {
  font-size: 15px;
  color: #314259;
  margin-bottom: 10px;
  text-wrap: balance;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 45px;
  flex-direction: row;
  width: 85%;
  margin: 20px auto;
  padding: 20px;
}
.reverse {
  flex-direction: row;
}
.detail img {
  max-width: 535px;
  max-height: 430px;
}
.detail h1 {
  color: #314259;
  font-size: 44px;
  margin-top: 1.2em;
  margin-bottom: 0.5em;
}
.displayed-text {
  color: #314259;
  font-size: 18px;
}
.read-more-button {
  color: #ed1a3b;
  font-size: 18px;
}
.accordion {
  width: 85%;
  margin-inline: auto;
}
.asked {
  font-size: 44px !important;
  font-weight: 700 !important;
  color: #314259;
  text-align: center;
  margin-block: 45px;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.accordion-header {
  padding-block: 18px !important;
}
.accordion-button {
  color: #3f4155;
  font-size: 18px;
  font-weight: 700;
}
.accordion-body {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .header-section {
    flex-direction: column;
    width: 96%;
    margin-inline: auto;
  }
  .feature,
  .accordion {
    width: 96%;
  }
  .detail {
    flex-direction: column;
    width: 96%;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .header-section h1 {
    margin-top: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    color: #314259;
  }
  .header-section img {
    display: none;
  }
  .header-section button {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .feature .tab {
    margin-top: 25px;
  }
  .feature img,
  .feature h4,
  .feature p {
    text-align: center;
  }
  .feature img {
    position: relative;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
}

/* navbar */
.nav-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  flex-direction: row;
  width: 90%;
  margin: 10px auto;
  height: 65px;
  background: #314259;
  border: 2px solid #b7b6b6;
  border-radius: 20px;
  /* position: fixed;
  left: 50%;
  top: 5px;
  transform: translateX(-50%); */
  padding: 10px;
}
.nav-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  width: 85%;
  margin: 20px auto;
  padding: 20px;
}
.nav-tab p {
  border-radius: 30px;
  border: 2px solid transparent;
  font-size: 20px;
  color: #314259;
  margin-bottom: 1px !important;
  text-wrap: balance;
  cursor: pointer;
  padding: 10px 20px;
  background: transparent;
  transition: border 0.5s ease, background 0.5s ease;
}

.nav-tab p:hover {
  border: 2px solid #ed1a3b;
  background: #e4869454;
}

.nav-header-section button {
  background-color: #ed1a3b;
  color: #fff;
  font-size: 18px;
  padding: 8px 30px;
  border-radius: 15px;
  border: 1px solid #ed1a3b;
  margin: 0 20px 0 20px;
}

/* FOOTER */
.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 35px;
  flex-direction: row;
  width: 85%;
  margin: 25px auto 10px;
  height: 65px;
  border-top: 2px solid #b7b6b6;
  padding: 10px;
}
.footer-section .social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.footer-section .social .social-icon {
  width: 30px;
  height: 30px;
  padding: 5px;
  /* border: 1px solid #314259; */
  /* border-radius: 8px; */
  cursor: pointer;
}
.footer-section .social .social-icon:hover {
  box-shadow: #314259;
  background-color: #3f415547;
  border-radius: 8px;
}

@media only screen and (max-width: 426px) {
  .footer-section {
    flex-direction: column;
    gap: 15px;
  }
  .detail img {
    max-width: 375px;
    max-height: 430px;
  }
}
@media only screen and (max-width: 375px) {
  .detail img {
    max-width: 320px;
    max-height: 430px;
  }
}
/* anchor text */
.show-more-text-container {
  font-size: 18px;
  line-height: 1.6;
}
.my-anchor-css-class {
  color: #ff0000;
  font-weight: 600;
  font-size: 16px;
}

.my-anchor-css-class:hover {
  cursor: pointer;
}
